// handle show more truncation

document
  .querySelectorAll('[data-show-more-truncate-controller]')
  .forEach((el) => {
    // defaulting to > 2 direct children
    const contentEl = el.querySelector('[data-show-more-truncate-content]');
    const activator = el.querySelector('[data-show-more-truncate-activator]');
    // if there's no way to expand the content, don't truncate it
    if (!activator) {
      return
    }

    const directChildren = contentEl.querySelectorAll(':scope > *');
    const hiddenWrapper = document.createElement('span');
    if (directChildren.length > 2) {
      hiddenWrapper.classList.add('hidden');
      directChildren.forEach((child, i) => {
        if (i > 2) {
          hiddenWrapper.append(child);
        }
      });
      if (hiddenWrapper.hasChildNodes()) {
        contentEl.append(hiddenWrapper);
      }
      activator.addEventListener('click', () => {
        hiddenWrapper.classList.remove('hidden');
        activator.remove();
      });

    } else {
      activator.remove();
    }
  });