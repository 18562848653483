import Glide from "@glidejs/glide";
import {initializeArticleSlider} from "~/components/shared/articles-slider";

// set up review slider
const reviewsSliderSelector = '.directory-reviews__container.glide';
const reviewsSliderEl = document.querySelector(reviewsSliderSelector);
if (reviewsSliderEl) {
  const reviewsSlider = new Glide(reviewsSliderSelector, {
    perView: 1.8,
    gap: 20,
    peek: {
      before: 0,
      after: 40,
    },
    breakpoints: {
      650: {
        perView: 1.2,
        gap: 10,
        peek: {
          before: 0,
          after: 32,
        },
      },
    },
  });
  reviewsSlider.mount();
}

// set up article slider

const articleSliderSelector = '.directory-featured-articles.glide';
initializeArticleSlider(articleSliderSelector);

// handle click anywhere

document.querySelectorAll('[data-click-anywhere-controller]').forEach((el) => {
  const linkEl = el.querySelector('[data-click-anywhere-link]');
  el.addEventListener('click', (e) => {
    const tooltipEl = document.querySelector('[data-tooltip-content].opened');
    if (tooltipEl) {
      return;
    }
    e.stopPropagation();
    if (linkEl !== e.target && !linkEl.contains(e.target)) {
      if (e.target.nodeName === 'A') {
        return;
      }
      window.open(linkEl.href, '_blank');
    }
  });
});

