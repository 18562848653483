
import { loadMap } from "~/components/b2c/directory/mapbox_map";



import "~/components/b2c/find_care_form";
import "~/components/shared/tracking/globalTracking";

import { setUpModals } from "~/components/shared/controllers/modalController";
import "~/components/shared/overflowExpandableController";


import "~/components/shared/controllers/slideOutController";
import "~/components/shared/controllers/expansionController";
import "~/components/shared/controllers/showMoreController";
import "~/components/shared/controllers/tooltipController";
import '~/components/shared/containerQueryPolyfill';
import '~/components/shared/navigation';
import '~/components/b2c/directory/bookableTimes';
import '~/components/b2c/directory/nonApiBookableTimes';
import '~/components/b2c/directory/fiveStarSlider';
import '~/components/shared/controllers/facetedFilterController';
import '~/components/shared/controllers/autocompleteController';
import { setUpGeolocation } from '~/components/b2c/directory/browserGeolocation';

import '~/components/shared/globalFooter';
import '~/components/directory/headerSearch';
import '~/components/directory/truncation';
import '~/components/directory/sliders';


if (document.body.id === 'ppc_landing_page') {
  import('~/components/b2c/ppc_landing_page/reviewsSlider');
  import('~/components/b2c/ppc_landing_page/howItWorksSlider');
  setUpGeolocation()
} else {
  setUpModals();
}

if (document.body.dataset.directoryLevel === 'ROOT') {
  setUpGeolocation();
}

loadMap();
