// set up five star slider if it exists
import Glide from "@glidejs/glide";

const fiveStarSliderSelector = '.five-star-reviews.glide';
if (document.querySelector(fiveStarSliderSelector)) {
  const config = {
    type: 'carousel',
    perView: 3,
    gap: 20,
    peek: {
      before: 10,
      after: 10,
    },
    breakpoints: {
      500: {
        perView: 1.2,
        gap: 16,
        peek: {
          before: 16,
          after: 0,
        }
      },
      767: {
        perView: 1.5,
        gap: 25,
        peek: {
          before: 25,
          after: 0,
        }
      },
      1150: {
        perView: 1,
        gap: 50,
        peek: {
          before: 25,
          after: 25,
        },
      },
    }
  };

  if (document.body.classList.contains('faceted-search')){
    config.breakpoints[1150].perView = 2;
    config.breakpoints[1150].gap = 25;
  }

  const fiveStarSlider = new Glide(fiveStarSliderSelector, config);
  fiveStarSlider.on('mount.after', () => {
    // This is needed to trigger the overflowExpandableController.
    // If we migrate to stimulus we can instead send the event directly to the controller
    window.dispatchEvent(new Event('resize'));
  });
  fiveStarSlider.mount();
}
